import React from 'react'
import ContactPage from '../ContactPage'

function Contacts() {
  return (
    <>
    <ContactPage />
    </>
  )
}

export default Contacts