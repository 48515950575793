import React from 'react'
import ProjectPage from '../ProjectPage'
import AboutMe from '../AboutMe'

function Projects() {
  return (
    <>
    <AboutMe />
    <ProjectPage />
    </>
  )
}

export default Projects