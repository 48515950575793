import React from "react";
import '../../App.css';
// import AboutMe from "../AboutMe";
import Cards from "../Cards";



export default function About() {



    return (
        <>
            {/* <AboutMe /> */}
            <Cards />
        </>

    )
}